<!--
Entirely responsible for retrieving and rendering attached check in s
-->
<template>
  <v-card>
    <v-card-subtitle>
      Check-In Information
    </v-card-subtitle>
    <v-simple-table
      class="pb-8"
      dense
      v-if="checkIns.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Check-In
            </th>
            <th>
              Status
            </th>
            <th>
              Has Images
            </th>
            <th>
              Converter Pieces
            </th>
            <th>
              Converters
            </th>
            <th>
              Other
            </th>
            <th class="text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ci in checkIns"
            :key="ci.url"
          >
            <td>
              <router-link :to="{ name: 'check-in', params: { id: ci.id}}">
                Check In #{{ ci.id }}
              </router-link>
            </td>
            <td>
              {{ getStatusesMethod(ci) && getStatusesMethod(ci).text }}
            </td>
            <td>
              {{ ci.checkinimage_set.length ? 'yes' : 'no' }}
            </td>
            <td>
              {{ ci.total_counts.total }}
            </td>
            <td>
              ${{ Math.round(ci.total_prices.total) }}
            </td>
            <td>
              ${{ Math.round(ci.total_prices.other) }}
            </td>
            <td class="text-right">
              ${{ Math.round(ci.total_prices.total + ci.total_prices.other) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td />
            <td />
            <td />
            <td>
              <strong>{{ converterPieceTotal }}</strong>
            </td>
            <td>
              <strong>${{ Math.round(converterPriceTotal) }}</strong>
            </td>
            <td>
              <strong>${{ Math.round(otherTotal) }}</strong>
            </td>
            <td class="text-right">
              <strong>${{ Math.round(converterPriceTotal + otherTotal) }}</strong>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <card-list-item
      v-else
      title="No Check-Ins Attached"
    />
    <base-button
      v-if="$store.getters.can('create-check-ins') && status === 'OP'"
      @clicked="$emit('addCheckIn')"
      fab
      small
      bottom
      right
      absolute
      icon="mdi-plus"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckInsSection',
  props: {
    checkIns: {
      type: Array,
      required: false,
      default: () => []
    },
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('checkIn', ['getStatusesMethod']),
    converterPieceTotal () {
      return this.checkIns.reduce((a, b) => {
        if (b.total_counts) a += b.total_counts.total
        return a
      }, 0)
    },
    converterPriceTotal () {
      return this.checkIns.reduce((a, b) => {
        if (b.total_prices) a += b.total_prices.total
        return a
      }, 0)
    },
    otherTotal () {
      return this.checkIns.reduce((a, b) => {
        if (b.total_prices) a += b.total_prices.other
        return a
      }, 0)
    }
  }
}
</script>
