<script>
import DataDumpReport from '@/base/filter/DataDumpReport'

export default {
  mixins: [DataDumpReport],
  name: 'CheckInDumpReport',
  data: function () {
    return {
      title: 'Check In Data Dump Report',
      action: 'view-check-in-dump-report',

      baseUrl: '/api/check_ins/',
      showAllBelow: false,
      ready: false,
      exportData: [],
      possibleFields: [],

      fields: [
        'id',
        'yard_id',
        'yard_name',
        'status',
        'transport_method',
        'total_counts',
        'total_prices',
        'created_by',
        'created_at',
        'purchase_division_code',
        'purchase_designation'
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Yard Name', prop: 'customerName', type: String, query: 'yard__name__icontains' },
        { name: 'Yard ID', prop: 'yardName', type: String, query: 'yard__name__iexact' },
        { name: 'Purchase ID', prop: 'purchaseId', type: String, query: 'purchase__id' },
        { name: 'Created By', prop: 'createdBy', type: String, query: 'created_by__username__iexact' },

        { name: 'Transport Method', prop: 'transportMethodSet', type: Array, query: 'transport_method__in' },
        { name: 'Payment Methods', prop: 'paymentMethodSet', type: Array, query: 'payment_method__in' },
        { name: 'Payment Types', prop: 'paymentTypeSet', type: Array, query: 'payment_type__in' },
        { name: 'Statuses', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'lot__division__code__in' },
        { name: 'Designations', prop: 'designationSet', type: Array, query: 'purchase__designation__in' },

        { name: 'Invoiced', prop: 'invoiced', type: String, query: 'invoiced' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ])
    }
  }
}
</script>
