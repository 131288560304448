<template>
  <div style="width: 100%;">
    <v-simple-table
      class="float-right"
      dense
    >
      <thead>
        <tr>
          <th>foils</th>
          <th>general</th>
          <th>
            {{ getShowFullValue ? 'pieces' : 'value' }}
          </th>
          <th v-if="getCheckIn.show_overall_average">
            average
          </th>
          <th>conv. total</th>
          <th>total cost</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ foilCount }}</td>
          <td>{{ generalCount }}</td>
          <td>{{ totalCount }}</td>
          <td v-if="getCheckIn.show_overall_average">
            {{ averageCost() | trimDecimals | addCommas | dollarSign }}
          </td>
          <td>{{ Math.round(converterCost) | trimDecimals | addCommas | dollarSign }}</td>
          <td>{{ Math.round(totalCost()) | trimDecimals | addCommas | dollarSign }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckInFooter',
  computed: {
    ...mapGetters('checkIn/edit', [
      'getShowFullValue',
      'getCheckIn',
      'getLines',
      'getFoilCount',
      'getFoilValue',
      'getAllExceptFoilCount',
      'getAllExceptFoilValue',
      'getAllCount',
      'getAllValue',
      'getDollarTotal'
    ]),
    converterCost () {
      return this.getDollarTotal
    },
    lineCost () {
      if (!this.getLines) return 0
      return Math.round(this.getLines.reduce((total, line) => {
        return total + (line.count * parseFloat(line.price))
      }, 0))
    },
    totalCount () {
      return (this.getShowFullValue) ? this.getAllCount : this.getAllValue
    },
    foilCount () {
      return (this.getShowFullValue) ? this.getFoilCount : this.getFoilValue
    },
    generalCount () {
      return (this.getShowFullValue) ? this.getAllExceptFoilCount : this.getAllExceptFoilValue
    }
  },
  methods: {
    // Get the average cost of a converter
    averageCost () {
      return this.converterCost && this.totalCount ? (this.converterCost / this.totalCount).toFixed(2) : 0
    },
    // Get the total cost for the check in
    totalCost () {
      return this.converterCost + this.lineCost
    }
  }
}
</script>
