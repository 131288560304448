<template>
  <unauthorized
    :authorized="$store.getters.can('view-purchase')"
    message="Not Authorized to View a Check In"
  >
    <v-container>
      <url-breadcrumbs
        :items="breadcrumbs"
      />
      <base-title v-if="!loading && checkIn">
        {{ `Check-In ID #${checkIn.id}` }} <v-chip
          v-if="checkIn.read_only"
          x-small
          color="success"
        >
          readonly
        </v-chip>
        <div
          v-if="checkIn.cart_sequence_id"
          class="caption"
        >
          Exchange Cart #{{ checkIn.cart_sequence_id }}
        </div>
        <template slot="subtitle">
          <router-link
            :to="{ name: 'yard', params: { id: checkIn.yard.id } }"
          >
            <h3>
              {{ checkIn.yard && checkIn.yard.name }}
            </h3>
          </router-link>
          {{ getStatusesMethod(checkIn) && getStatusesMethod(checkIn).text }}
        </template>
      </base-title>
      <app-loading-indicator
        v-if="loading"
      />
      <div
        v-else
      >
        <v-toolbar
          dense
          flat
        >
          <user-upload-dialog
            :file-links="fileLinks"
          />
          <v-spacer />
          <v-menu
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <pdf-make-btn
                include-pgm-header
                title="Print receipt"
                :doc-def-builder="pdfDoc"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item
                    @click="on"
                  >
                    Print receipt
                  </v-list-item>
                </template>
              </pdf-make-btn>
              <skid-labels-editor
                :adding-skids="false"
                :check-in-skids="checkIn.skids"
                :check-in="checkIn"
                is-small-btn
              >
                <template v-slot:activator="{ on, disabled }">
                  <v-list-item
                    :disabled="disabled"
                    @click="on"
                  >
                    Print Skids
                  </v-list-item>
                </template>
              </skid-labels-editor>
              <info-tooltip>
                <template v-slot:wrap-me>
                  <v-list-item
                    color="accent"
                    :disabled="!['PR', 'CH'].includes(checkIn.status)"
                    @click="emailDialog = true"
                    small
                  >
                    Re-Send Emails
                  </v-list-item>
                </template>
                Requires Preliminary or Complete Status
              </info-tooltip>
              <reconcile-dialog
                v-if="checkIn"
                :check-in="checkIn"
              />
              <info-tooltip>
                <template v-slot:wrap-me>
                  <v-list-item
                    small
                    :disabled="!$store.getters.can('delete-check-ins') || !canDeleteCheckIns"
                    @click="deleteDialog = true"
                  >
                    Delete
                  </v-list-item>
                </template>
                You may only delete Check-ins if you are the creator <b>AND</b> the status is OPEN or PRELIMINARY
              </info-tooltip>
              <v-list-item
                v-if="$store.getters.can('edit-check-in-created-for')"
                @click="createdForDialog = true"
              >
                Edit Created For
              </v-list-item>
              <v-list-item
                v-if="$store.getters.can('edit-skids-when-check-in-completed')"
                :disabled="checkIn.status !== 'CH'"
                @click="editSkidsDialog = true"
              >
                Edit Skids
              </v-list-item>
              <v-list-item
                :disabled="checkIn.read_only"
                :to="{ name: 'check-in-modify', params: { id: this.$route.params.id } }"
              >
                Edit Data
              </v-list-item>
              <v-list-item
                :disabled="!$store.getters.can('create-check-ins')"
                :to="{ name: 'check-in-split', params: { id: this.$route.params.id } }"
              >
                Split Check-In
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <edit-dialog
          v-model="emailDialog"
          headline-text="Send the emails"
          accept-text="send"
          @accept="sendEmails"
        >
          Are you sure you would like to send the check in emails?
        </edit-dialog>
        <edit-dialog
          v-if="$store.getters.can('edit-check-in-created-for')"
          v-model="createdForDialog"
          headline-text="Edit Check-In's Created For Value"
          @accept="updateCheckInCreatedFor"
        >
          <p>
            Change which buyer this Check-In is assigned to.
            <br>
            Cat Check-In reports generated for specific users will populate from each Check-In's created for field.
          </p>
          <search-autocomplete
            v-model="createdFor"
            :params="{ fields: 'username,id', ordering: 'username' }"
            label="Created By Search"
            api-path="/api/users/"
            item-value="id"
            :format-entry="x => ({ friendlyName: x.username, username: x.username, id: x.id })"
          />
        </edit-dialog>
        <edit-dialog
          v-model="deleteDialog"
          headline-text="Delete Check-In"
          accept-text="delete"
          @accept="confirmDeleteCheckIn"
        >
          Are you sure you would like this Check-In to be permanently deleted?
        </edit-dialog>
        <edit-dialog
          v-model="editSkidsDialog"
          headline-text="Edit Skids"
          accept-text="done"
          @accept="editSkidsDialog = false"
        >
          <!--Skids Section-->
          <add-check-in-skids
            :skids="checkIn.skids"
            :adding-skid="addingSkid"
            :check-in="checkIn"
            :updating-skid="updatingSkid"
            :deleting-skid="deletingSkid"
            @deleteSkid="deleteSkid"
            @updateSkid="updateSkid"
            @addSkid="addSkid"
          />
        </edit-dialog>
        <v-card>
          <v-card-subtitle>
            Check-In Details
          </v-card-subtitle>
          <v-row>
            <card-list-item title="Purchase ID">
              <router-link :to="`/purchase/${getPurchaseId(checkIn.purchase)}`">
                {{ `Purchase ${getPurchaseId(checkIn.purchase)}` }}
              </router-link>
            </card-list-item>
            <card-list-item title="External Reference">
              {{ checkIn.customer_ref | filterNA }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="Requires Transit">
              {{ (checkIn.requires_transit) ? 'Yes' : 'No' }}
            </card-list-item>
            <card-list-item title="Currency">
              {{ checkIn.currency_text }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="Grand Total">
              {{
                checkIn.total_prices.other + checkIn.total_prices.total | filterNA | trimDecimals | addCommas | dollarSign
              }}
            </card-list-item>
            <card-list-item title="Initial Payment">
              <span v-if="checkIn.payment_type !== 'UNP'">
                {{ getPaymentMethodText(checkIn.payment_method) }}
                {{ checkIn.payment_amount | currencyUSD }}
                ({{ getPaymentTypeText(checkIn.payment_type) }})
              </span>
              <span v-else>Unpaid</span>
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="Intended Lot">
              {{ checkIn.intended_lot ? checkIn.intended_lot.name : '--' }}
            </card-list-item>
            <card-list-item title="Intended Hedge">
              {{ checkIn.intended_hedge_id || '--' }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="Current Location">
              <location-block
                v-if="checkIn.current_location"
                :location="checkIn.current_location"
              />
            </card-list-item>
            <card-list-item title="To Location">
              <location-block
                v-if="checkIn.to_location"
                :location="checkIn.to_location"
              />
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item
              title="Notes"
            >
              {{ checkIn.notes | filterNA }}
            </card-list-item>
            <card-list-item
              title="Notes (External)"
            >
              {{ checkIn.public_notes | filterNA }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="Completed On">
              {{ checkIn.completed_at | formatDate }}
            </card-list-item>
            <card-list-item title="Received On">
              {{ checkIn.received_date | formatDate }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item
              title="Created by"
            >
              {{ checkIn.created_by_username }}
            </card-list-item>
            <card-list-item
              title="Created for"
            >
              {{ checkIn.created_for_username }}
            </card-list-item>
          </v-row>
        </v-card>
        <v-card
          v-if="$store.getters.can('view-purchase')"
          class="mt-8"
        >
          <v-card-subtitle>
            Metal Rates Used
          </v-card-subtitle>
          <v-row>
            <card-list-item
              :breakpoint-col-val="6"
              :other-col-val="4"
              title="Platinum"
            >
              <span class="pt-color">
                {{ checkIn.pt_rate | currencyUSD }}
              </span>
            </card-list-item>
            <card-list-item
              :breakpoint-col-val="6"
              :other-col-val="4"
              title="Palladium"
            >
              <span class="pd-color">
                {{ checkIn.pd_rate | currencyUSD }}
              </span>
            </card-list-item>
            <card-list-item
              :breakpoint-col-val="6"
              :other-col-val="4"
              title="Rhodium"
            >
              <span class="rh-color">
                {{ checkIn.rh_rate | currencyUSD }}
              </span>
            </card-list-item>
          </v-row>
        </v-card>
        <ppm-value-card
          v-if="$store.getters.can('view-purchase') && !loading"
          :pt="estimatedToz.pt"
          :pd="estimatedToz.pd"
          :rh="estimatedToz.rh"
          class="mt-8"
        >
          <v-card-subtitle>
            Contained toz (estimated from lines)
            <info-tooltip>
              Calculated based on the accumulation of converter.converter_line.value *
              converter.converter_line.converter[est_toz]
            </info-tooltip>
          </v-card-subtitle>
        </ppm-value-card>
        <customer-section
          :check-in="checkIn"
          class="mt-8"
        />
        <v-card
          class="mt-8"
        >
          <v-card-subtitle
            @click="showPreliminary = !showPreliminary"
          >
            Preliminary information
            <span>
              <v-btn
                x-small
                icon
              >
                <v-icon v-if="!showPreliminary">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </v-btn>
            </span>
          </v-card-subtitle>
          <v-row v-if="showPreliminary">
            <card-list-item title="Estimated Pieces">
              {{ checkIn.preliminary_pieces || '--' }}
            </card-list-item>
            <card-list-item title="Estimated Weight">
              {{ checkIn.preliminary_weight || '--' }}
            </card-list-item>
            <card-list-item title="Expected Skid Count">
              {{ checkIn.preliminary_skid_count || '--' }}
            </card-list-item>
            <card-list-item title="Quoted Unit Rate">
              {{ checkIn.preliminary_unit_rate || '--' }}
            </card-list-item>
          </v-row>
        </v-card>
        <v-card class="mt-8">
          <v-card-subtitle class="subtitle-2 mb-n4">
            Other Purchases
          </v-card-subtitle>
          <v-data-table
            v-if="checkIn.check_in_lines && checkIn.check_in_lines.length > 0"
            :headers="checkInLineTableHeaders"
            :items="checkIn.check_in_lines"
          >
            <template v-slot:item.catalog="{ item }">
              {{ item.catalog | filterObject('name') | filterNA }}
            </template>
            <template v-slot:item.count="{ item }">
              {{ item.count | filterNA | addCommas }}
            </template>
            <template v-slot:item.nav_code="{ item }">
              {{ getLineAverage(item) | filterNA | trimDecimals | addCommas | dollarSign }}
            </template>
            <template v-slot:item.total_price="{ item }">
              {{ item.total_price | filterNA | trimDecimals | addCommas | dollarSign }}
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="3">
                  Total - {{ checkIn.currency }}
                </td>
                <td>{{ checkIn.total_prices.other | filterNA | trimDecimals | addCommas | dollarSign }}</td>
              </tr>
            </template>
          </v-data-table>
          <h4
            v-else
            class="text-center"
          >
            No Other Lines
          </h4>
        </v-card>
        <v-card class="mt-8">
          <v-card-subtitle>
            Converter Totals
          </v-card-subtitle>
          <v-simple-table>
            <thead>
              <tr>
                <th>description</th>
                <th>count</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="value in converterTotals"
                :key="value"
              >
                <template v-if="checkIn.total_counts[value]">
                  <td>{{ value | converterType }}</td>
                  <td>{{ checkIn.total_counts[value] | filterNA | trimDecimals | addCommas | appendPcs }}</td>
                  <td>{{ checkIn.total_prices[value] | filterNA | trimDecimals | addCommas | dollarSign }}</td>
                </template>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total - {{ checkIn.currency }}</td>
                <td>{{ checkIn.total_counts.total | filterNA | toFixed(0) | addCommas | appendPcs }}</td>
                <td>{{ checkIn.total_prices.total | filterNA | trimDecimals | addCommas | dollarSign }}</td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card>
        <v-card class="mt-8">
          <v-card-subtitle>
            Converters
          </v-card-subtitle>
          <v-data-table
            v-if="converterLines && converterLines.length > 0"
            :headers="converterTableHeaders"
            :items="converterLines"
          >
            <template v-slot:item.converter="{ item }">
              {{ item.converter | filterObject('code') | filterNA }}
            </template>
            <template v-slot:item.value="{ item }">
              {{ item.value | filterNA | addCommas | appendPcs }}
            </template>
            <template v-slot:item.count="{ item }">
              {{ item.count | filterNA | trimDecimals | addCommas | appendPcs }}
            </template>
            <template v-slot:item.price="{ item }">
              {{ getConverterAverage(item) | filterNA | toFixed(2) | trimDecimals | addCommas | dollarSign }}
            </template>
            <template v-slot:item.total_price="{ item }">
              {{ item.total_price | filterNA | toFixed(2) | trimDecimals | addCommas | dollarSign }}
            </template>
          </v-data-table>
          <h3
            v-else
            class="text-center"
          >
            No Converters Found
          </h3>
        </v-card>
        <v-card class="mt-8">
          <v-card-subtitle>Skids</v-card-subtitle>
          <v-card-text>
            <v-data-table
              multi-sort
              :headers="skidTableHeaders"
              :items="checkIn.skids"
              style="cursor: pointer"
            >
              <template v-slot:item.gps_number="{ item }">
                {{ item.gps_number | filterNA }}
              </template>
              <template v-slot:item.expected_pieces="{ item }">
                {{ item.expected_pieces | trimDecimals | addCommas | appendPcs }}
              </template>
              <template v-slot:item.expected_weight="{ item }">
                {{ item.expected_weight | trimDecimals | addCommas | appendLbs }}
              </template>
              <template v-slot:item.weight="{ item }">
                {{ item.weight | filterNA | trimDecimals | addCommas | appendLbs }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
        <span v-if="$store.getters.can('create-check-ins')">
          <fixed-text-fab
            v-if="!loading && (checkIn.status === 'PR' || checkIn.status === 'OP')"
            icon-text="mdi-table"
            @click="$router.push({ name: 'edit-checkin', params: { id: checkIn.id} })"
          >
            Edit Lines
          </fixed-text-fab>
          <fixed-text-fab
            v-else-if="!loading && checkIn.status === 'CH' && checkIn.customer_name === null"
            @click="$router.push({ name: 'payment-checkin', params: { id: checkIn.id} })"
          >
            Complete Checkout
          </fixed-text-fab>
        </span>
      </div>
    </v-container>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import SkidLabelsEditor from '@/views/CheckIns/components/SkidLabelsEditor'
import CardListItem from '@/base/components/CardListItem'
import CustomerSection from './components/CustomerSection'
import LocationBlock from '../Logistics/Transit/components/LocationBlock'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import fileLinks from '@/helpers/fileLinks'
import PdfMakeBtn from '@/base/components/PdfMakeBtn.vue'
import { checkInReceipt } from '@/helpers/pdfMake/checkInReceipt'
import ReconcileDialog from './components/ReconcileDialog'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
import AddCheckInSkids from '@/views/CheckIns/Complete/components/AddCheckInSkids'
import PpmValueCard from '@/components/PpmValueCard.vue'
import FixedTextFab from '@/components/Buttons/FixedTextFab.vue'

export default {
  name: 'CheckIn',
  components: {
    FixedTextFab,
    PpmValueCard,
    UserUploadDialog,
    LocationBlock,
    CardListItem,
    CustomerSection,
    PdfMakeBtn,
    SkidLabelsEditor,
    ReconcileDialog,
    SearchAutocomplete,
    AddCheckInSkids
  },
  data: () => ({
    createdFor: null,
    createdForDialog: false,
    deleteDialog: false,
    emailDialog: false,
    editSkidsDialog: false,
    addingSkid: false,
    updatingSkid: false,
    deletingSkid: false,
    showPreliminary: false,
    loading: true,
    converterTableHeaders: [
      { text: 'Item', value: 'converter' },
      { text: 'Total Full', value: 'value' },
      { text: 'Total Pcs', value: 'count' },
      { text: 'Average Price', value: 'price' },
      { text: 'Total Price', value: 'total_price' }
    ],
    checkInLineTableHeaders: [
      { text: 'Item', value: 'catalog' },
      { text: 'Quantity', value: 'count' },
      { text: 'Average Price', value: 'nav_code' },
      { text: 'Total Price', value: 'total_price' }
    ],
    skidTableHeaders: [
      { text: '', value: 'name' },
      { text: 'GPS Number', value: 'gps_number' },
      { text: 'Est. Pieces', value: 'expected_pieces' },
      { text: 'Est. Weight', value: 'expected_weight' },
      { text: 'Weight', value: 'weight' }
    ],
    converterTotals: ['AM', 'DF', 'IF', 'OM', 'DP', 'DS', 'GN', 'BD'],
    addingImages: false,
    timeout: null,
    estimatedToz: {
      pt: 0,
      pd: 0,
      rh: 0
    }
  }),
  computed: {
    ...mapGetters({
      getStatusesMethod: 'checkIn/getStatusesMethod',
      checkIn: 'checkIn/edit/getCheckIn',
      images: 'checkIn/images',
      user: 'currentUser',
      skids: 'checkInSkidStore/checkInSkids'
    }),
    fileLinks () {
      return fileLinks.checkInFileLinks(this.checkIn)
    },
    converterLines () {
      return this.checkIn.converter_lines.filter(line => !!line.count)
    },
    breadcrumbs () {
      if (!this.checkIn) return []
      return [
        this.checkIn.purchase,
        this.checkIn.url
      ]
    },
    canDeleteCheckIns () {
      if (this.user.id === this.checkIn.created_by_id && ['OP', 'PR'].includes(this.checkIn.status)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCheckInImages: 'checkIn/fetchCheckInImages',
      fetchCheckInDetailed: 'checkIn/edit/fetchCheckInDetailed',
      updateCheckIn: 'checkIn/updateCheckIn',
      sendCheckInEmail: 'checkIn/sendCheckInEmail',
      deleteCheckIn: 'checkIn/deleteCheckIn',
      fetchCheckInSkids: 'checkInSkidStore/fetchCheckInSkids',
      deleteCheckInSkid: 'checkInSkidStore/deleteCheckInSkid',
      generateCheckInSkid: 'checkInSkidStore/generateCheckInSkid',
      updateCheckInSkid: 'checkInSkidStore/updateCheckInSkid'
    }),
    pdfDoc () {
      return checkInReceipt(this.checkIn.id)
    },
    confirmDeleteCheckIn () {
      let purchaseId = this.checkIn.purchase_id
      this.deleteCheckIn(this.checkIn.id).then(r => {
        if (r.status === 204) {
          this.$router.push({ name: 'purchase', params: { id: purchaseId } })
        }
      })
    },
    /**
     * Add the given skid
     */
    addSkid () {
      this.addingSkid = true
      const params = { checkIn: this.checkIn, totalPieces: this.totalCount }
      this.generateCheckInSkid(params).then(() => {
        this.getSkids().then(() => {
          this.addingSkid = false
          this.checkIn.skids = this.skids
        })
      })
    },
    /**
     * Add the given skid
     * @param {Object} skid
     */
    updateSkid (skid) {
      this.updatingSkid = true
      if (this.checkIn.dock_buy) skid.weight = skid.expected_weight
      this.updateCheckInSkid(skid).then(() => {
        this.getSkids().then(() => {
          this.updatingSkid = false
          this.checkIn.skids = this.skids
        })
      })
    },
    getSkids () {
      return this.fetchCheckInSkids({ check_in_id: this.$route.params.id, page_size: 0 })
    },
    /**
     * Delete the given skid given its id
     * @param {Number} id
     */
    deleteSkid (id) {
      this.deletingSkid = true
      this.deleteCheckInSkid(id).then(() => {
        this.getSkids().then(() => {
          this.deletingSkid = false
          this.checkIn.skids = this.skids
        })
      })
    },
    sendEmails () {
      this.sendCheckInEmail({ id: this.checkIn.id }).then(response => {
        if (response.status === 201) {
          this.$store.commit('setSnackbarInfo', 'Check In emails have been resent')
        }
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to send resend emails')
      })
    },
    getPaymentMethodText (value) {
      const pm = this.$store.getters['getPaymentMethods'].find(pm => pm.value === value)
      if (pm) return pm.text
      return ''
    },
    getPaymentTypeText (value) {
      const pt = this.$store.getters['getPaymentTypes'].find(pm => pm.value === value)
      if (pt) return pt.text
      return ''
    },
    fetchCheckIn () {
      return this.fetchCheckInDetailed({ checkinID: this.$route.params.id })
    },
    fetchImages () {
      return this.fetchCheckInImages({ check_in_id: this.$route.params.id })
    },
    /**
     * Get the purchase id from the check in based on its url
     * @param {String} url
     */
    getPurchaseId (url) {
      const values = url.split('/').filter(v => v !== '')
      return values[values.length - 1]
    },
    /**
     * Get the average price per quanity for a given check in line
     * @param {Object} line
     */
    getLineAverage (line) {
      const qty = parseFloat(line.count)
      const avg = qty === 0 ? 0 : line.total_price / qty
      return Number(avg).toFixed(2)
    },
    /**
     * Get the average price per cat for a given converter line
     * @param {Object} cvLine
     */
    getConverterAverage (cvLine) {
      const qty = this.getTotalConverterPieces(cvLine)
      return qty === 0 ? 0 : cvLine.total_price / qty
    },
    /**
     * Get the total number of pieces for the converter line
     * @param {Object} cvLine
     */
    getTotalConverterPieces (cvLine) {
      return parseFloat(cvLine.count)
    },
    addImages (images) {
      this.addingImages = true
      Promise.all(images.map(this.uploadImage)).finally(() => {
        this.fetchImages()
        this.addingImages = false
      })
    },
    async uploadImage (image) {
      const ciImage = await this.$store.dispatch('checkIn/createCheckInImage', {
        'ciId': this.checkIn.id,
        'ciUrl': this.checkIn.url,
        'imageName': image.name
      })
      const uploadUrl = await this.$store.dispatch('checkIn/getImageUrl', ciImage)
      await this.$store.dispatch('checkIn/putAwsFile', {
        'image': image,
        'ciImageUpload': uploadUrl,
        'url': ciImage.url
      })
    },
    async updateCheckInCreatedFor () {
      await this.updateCheckIn({
        id: this.checkIn.id,
        formData: {
          created_for_id: this.createdFor
        }
      })
      this.fetchCheckIn()
    },
    async fetchContainedToz () {
      const fmtNumber = (value) => Number(value).toPrecision(5)
      try {
        const res = await axios.get(`/api/check_ins/${this.$route.params.id}/contained_toz/`)
        this.estimatedToz.pt = fmtNumber(res.data.pt)
        this.estimatedToz.pd = fmtNumber(res.data.pd)
        this.estimatedToz.rh = fmtNumber(res.data.rh)
      } catch (e) {
        this.$logger.error(e)
      }
    }
  },
  mounted () {
    Promise.all([
      this.fetchCheckIn(),
      this.fetchContainedToz(),
      this.fetchImages()
    ]).then(() => {
      this.loading = false
    })
  }
}
</script>
