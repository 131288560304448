<template>
  <unauthorized
    :authorized="$store.getters.can('create-check-ins')"
    message="Not Authorized to Create Check Ins"
  >
    <app-loading-indicator v-if="!getCheckIn || loading" />
    <unauthorized
      v-else
      :authorized="getCheckIn.status !== 'CH'"
      :route="{ name: 'checkin', params: { id: getCheckIn.id } }"
      :route-message="`Click here to go back to check in #${getCheckIn.id}`"
      message="Cannot edit a completed check in"
    >
      <v-container>
        <url-breadcrumbs
          :items="[
            getCheckIn.purchase,
            getCheckIn.url,
            ['Edit', { name: 'edit-checkin', params: { id: getCheckIn.id } }],
          ]"
        />
        <div class="mb-6">
          <base-title>
            {{ `Check-In ID #${getCheckIn.id}` }}
            <span slot="subtitle">
              <router-link
                :to="{ name: 'yard', params: { id: getCheckIn.yard.id } }"
              >
                {{ getCheckIn.yard.name }}
              </router-link>
              <br>
              {{ getStatusesMethod(getCheckIn) && getStatusesMethod(getCheckIn).text }} / {{ getCheckIn.currency }}
              <br>
            </span>
          </base-title>
          <!--App Buttons-->
          <div class="text-center">
            <div class="d-inline-block">
              <user-upload-dialog
                class="ma-2 d-inline-block"
                :file-links="fileLinks"
              />
              <v-btn
                class="ma-2"
                solo
                small
                color="primary"
                @click="pricingDialog = !pricingDialog"
              >
                config
              </v-btn>
            </div>
            <div class="d-inline-block">
              <v-btn
                class="ma-2"
                solo
                small
                color="primary"
                @click="searchDialog = !searchDialog"
              >
                Search
              </v-btn>
              <v-btn
                class="ma-2"
                solo
                small
                color="primary"
                :disabled="loadingCheckIn"
                :loading="loadingCheckIn"
                @click="completeCheckIn"
              >
                check out
              </v-btn>
            </div>
          </div>
          <v-card
            class="mx-auto"
            max-width="800"
            tile
          >
            <v-expansion-panels
              accordion
              focusable
              v-model="panel"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <strong>Converters</strong> {{ totalCount }} | ${{ Math.round(getDollarTotal) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="paddingless-expansion">
                  <v-row>
                    <v-col
                      cols="10"
                      offset="1"
                      sm="6"
                      offset-sm="3"
                    >
                      <v-text-field
                        label="Filter"
                        hint="Only the converters with matching names will appear"
                        v-model="converterFilter"
                        clearable
                        @input="page = 1"
                      />
                    </v-col>
                  </v-row>
                  <result-pagination
                    v-model="page"
                    :pages="pageCount"
                  />
                  <converter-line
                    v-for="(converter) in visibleConverters"
                    :key="converter.id"
                    :line="converter"
                  />
                  <result-pagination
                    v-model="page"
                    :pages="pageCount"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <strong>Lines</strong> {{ lineCount }} | ${{ lineTotal }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="paddingless-expansion">
                  <catalog-line
                    v-for="catalog in getLines"
                    :key="catalog.id"
                    :line="catalog"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <!--        <check-in-edit-footer :check-in="checkIn" />-->
          <search-dialog v-model="searchDialog" />
          <pricing-dialog
            v-model="pricingDialog"
            :check-in="getCheckIn"
          />
        </div>
        <v-footer
          app
          inset
        >
          <check-in-footer />
        </v-footer>
      </v-container>
    </unauthorized>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseTitle from '@/base/components/BaseTitle'
import CheckInFooter from '../components/CheckInFooter'
import PricingDialog from './components/PricingDialog'
import SearchDialog from './components/SearchDialog'
import ConverterLine from './components/ConverterLine'
import CatalogLine from './components/CatalogLine'
import ResultPagination from '@/base/components/ResultPagination'
import fileLinks from '@/helpers/fileLinks'
import UserUploadDialog from '@/base/components/UserUploadDialog'

export default {
  name: 'EditCheckIn',
  components: {
    UserUploadDialog,
    ResultPagination,
    PricingDialog,
    ConverterLine,
    CheckInFooter,
    CatalogLine,
    BaseTitle,
    SearchDialog
  },
  data: () => ({
    page: 1,
    converterFilter: '',
    addImageDialog: false,
    searchDialog: false,
    pricingDialog: false,
    loading: true,
    loadingCheckIn: false,
    panel: 0,
    addingImages: false,
    addingSkid: false
  }),
  methods: {
    ...mapActions('checkIn/edit', [
      'createCheckIn',
      'fetchCheckInDetailed',
      'fetchLatestMarketRate',
      'addCatalogLine',
      'patchCatalogLine',
      'addConverterLine',
      'patchConverterLine',
      'fetchPricingMinPercent'
    ]),
    loadCheckIn (checkinID) {
      this.fetchCheckInDetailed({ checkinID }).then(() => {
        this.fetchPricingMinPercent(checkinID)
        this.loading = false
      })
    },
    /**
     * Route the user to the complete checkin page
     */
    completeCheckIn () {
      this.loadingCheckIn = true
      this.fetchCheckInDetailed({ checkinID: this.$route.params.id }).then(() => {
        this.$router.push({ name: 'complete-checkin', params: { id: this.getCheckIn.id } })
        this.loadingCheckIn = false
      })
    },
    /**
     * Add the given images to the check in
     * @param {[FormData]} images
     */
    addImages (images) {
      this.addingImages = true
      Promise.all(images.map(this.uploadImage)).finally(() => {
        this.addingImages = false
      })
    },
    async uploadImage (image) {
      const ciImage = await this.$store.dispatch('checkIn/createCheckInImage', {
        'ciId': this.getCheckIn.id,
        'ciUrl': this.getCheckIn.url,
        'imageName': image.name
      })
      const uploadUrl = await this.$store.dispatch('checkIn/getImageUrl', ciImage)
      await this.$store.dispatch('checkIn/putAwsFile', {
        'image': image,
        'ciImageUpload': uploadUrl,
        'url': ciImage.url
      })
    },
    /**
     * Add a check in skid given the current piece counts
     */
    addSkid () {
      this.addingSkid = true
      const params = { checkIn: this.getCheckIn, totalPieces: this.getAllCount }
      this.$store.dispatch('checkInSkidStore/generateCheckInSkid', params)
        .then(() => { this.addingSkid = false })
    }
  },
  computed: {
    fileLinks () {
      return fileLinks.checkInFileLinks(this.getCheckIn)
    },
    pageLength () {
      if (this.getConverters.length < 50) return 50
      return 25
    },
    pageCount () {
      return Math.ceil(this.availableConverters.length / this.pageLength)
    },
    // Converters currently allow to appear in the results
    availableConverters () {
      let converters = this.getConverters
      if (this.converterFilter && this.converterFilter.length > 0) {
        const filter = this.converterFilter.toLowerCase()
        converters = converters.filter(cl => cl.converter.code.toLowerCase().includes(filter))
      }
      return converters
    },
    // Converters to show in the results
    visibleConverters () {
      const start = (this.page - 1) * this.pageLength
      const end = start + this.pageLength
      let lineNumber = start
      return this.availableConverters.slice(start, end).map(cv => {
        cv.lineNumber = ++lineNumber
        return cv
      })
    },
    ...mapGetters('checkIn', [
      'getStatusesMethod'
    ]),
    ...mapGetters('checkIn/edit', [
      'getShowFullValue',
      'getCheckIn',
      'getMarketRate',
      'getConverters',
      'getLines',
      'getLatestMarketRate',
      'getAllCount',
      'getAllValue',
      'getDollarTotal'
    ]),
    totalCount () {
      return (this.getShowFullValue) ? this.getAllCount : this.getAllValue
    },
    breadcrumbs () {
      return []
    },
    lineCount () {
      if (!this.getLines) return 0
      return this.getLines.reduce((count, line) => {
        return count + line.count
      }, 0)
    },
    lineTotal () {
      if (!this.getLines) return 0
      return Math.round(this.getLines.reduce((total, line) => {
        return total + (line.count * parseFloat(line.price))
      }, 0))
    }
  },
  mounted () {
    this.loadCheckIn(this.$route.params.id)
  },
  beforeRouteUpdate (to) {
    this.loadCheckIn(this.$route.params.id)
  }
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
