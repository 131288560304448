<template>
  <unauthorized
    :authorized="$store.getters.can('view-purchase')"
    message="Not Authorized to View Check Ins"
  >
    <base-title title="Check In List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchDivisions"
    >
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        start-date-label="Created Date From"
        end-date-label="Creation Date To"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Payment Methods"
            v-model="paymentMethodSet"
            :items="$store.getters['getPaymentMethods']"
            multiple
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Payment Types"
            v-model="paymentTypeSet"
            :items="$store.getters['getPaymentTypes']"
            multiple
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <search-autocomplete
            v-model="yardName"
            :params="{ page_size: 0, fields: 'name', ordering: 'name' }"
            label="Yard Search"
            api-path="/api/yards/"
            item-value="name"
            multiple
            :format-entry="x => ({ friendlyName: x.name, name: x.name })"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <search-autocomplete
            v-model="createdBy"
            :params="{ fields: 'username', ordering: 'username' }"
            label="Created By Search"
            api-path="/api/users/"
            item-value="username"
            multiple
            :format-entry="x => ({ friendlyName: x.username, username: x.username })"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Purchase ID"
            v-model="purchaseId"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Customer Name"
            v-model="customerName"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="External Ref."
            v-model="customerRef"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            label="Status"
            v-model="statusSet"
            :items="$store.getters['getCheckInStatuses']"
            multiple
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            label="Transport Method"
            v-model="transportMethodSet"
            :items="$store.getters['getTransportMethods']"
            multiple
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-select
            label="Invoiced"
            v-model="invoiced"
            :items="boolOptions"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Divisions"
            v-model="divisionSet"
            multiple
            :items="divisions"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Designation"
            v-model="designationSet"
            multiple
            :items="$store.getters['lotStore/lotTypes']"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Does Not Have Intake"
            v-model="needsIntake"
            :items="['True', 'False']"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Is Reconciled"
            v-model="isReconciled"
            :items="['True', 'False']"
          />
        </v-col>
      </v-row>
    </search-filters>
    <v-row>
      <v-col>
        <v-btn
          v-if="$store.getters.can('view-check-in-dump-report')"
          @click="routePageWithChips('check-in-dump-report')"
          color="primary"
          text
        >
          Report/Export
          <v-icon class="ml-2">
            mdi-database-export
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="check-in"
        />
      </template>
      <template v-slot:item.yard__name="{ item }">
        <router-link
          :to="{ name: 'yard', params: { id: item.yard_id } }"
        >
          {{ item.yard_name }}
        </router-link>
      </template>
      <template v-slot:item.purchase__division__code="{ item }">
        {{ item.purchase_division_code }}
      </template>
      <template v-slot:item.purchase__designation="{ item }">
        {{ item.purchase_designation }}
      </template>
      <template v-slot:item.created_by="{ item }">
        {{ (item.created_by && item.created_by.username) || '--' }}
      </template>
      <template v-slot:item.created_for="{ item }">
        {{ (item.created_for && item.created_for.username) || '--' }}
      </template>
      <template v-slot:item.total_counts.total="{ item }">
        {{ item.total_counts.total | trimDecimals | addCommas | appendPcs }}
      </template>
      <template v-slot:item.total_counts.FO="{ item }">
        {{ (item.total_counts.IF + item.total_counts.DF) | trimDecimals | addCommas | appendPcs }}
      </template>
      <template v-slot:item.total_counts.OM="{ item }">
        {{ item.total_counts.OM | trimDecimals | addCommas | appendPcs }}
      </template>
      <template v-slot:item.total_prices="{ item }">
        {{ item.total_prices.total | filterNA | trimDecimals | addCommas | dollarSign }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'CheckInList',
  components: {
    SearchAutocomplete,
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'checkin-list',
      baseUrl: '/api/check_ins/',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Cart ID', value: 'cart_sequence_id' },
        { text: 'Ref.', value: 'customer_ref' },
        { text: 'Yard', value: 'yard__name' },
        { text: 'Status', value: 'status' },
        { text: 'Division', value: 'purchase__division__code' },
        { text: 'Designation', value: 'purchase__designation' },
        { text: 'Transport', value: 'transport_method' },
        // { text: 'PMT Type', value: 'payment_method' },
        // { text: 'PMT Method', value: 'payment_type' },
        { text: 'Foil Count', sortable: false, value: 'total_counts.FO' },
        { text: 'OEM Count', sortable: false, value: 'total_counts.OM' },
        { text: 'Converter Count', sortable: false, value: 'total_counts.total' },
        { text: 'Total Cost', sortable: false, value: 'total_prices' },
        { text: 'Currency', sortable: false, value: 'currency' },
        { text: 'User', value: 'created_by' },
        { text: 'For', value: 'created_for' },
        { text: 'Created', value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Yard Name', prop: 'customerName', type: String, query: 'yard__name__icontains' },
        { name: 'Yard ID', prop: 'yardName', type: String, query: 'yard__name__iexact' },
        { name: 'Purchase ID', prop: 'purchaseId', type: String, query: 'purchase__id' },
        { name: 'Created By', prop: 'createdBy', type: String, query: 'created_by__username__iexact' },

        { name: 'Transport Method', prop: 'transportMethodSet', type: Array, query: 'transport_method__in' },
        { name: 'Payment Methods', prop: 'paymentMethodSet', type: Array, query: 'payment_method__in' },
        { name: 'Payment Types', prop: 'paymentTypeSet', type: Array, query: 'payment_type__in' },
        { name: 'Statuses', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'lot__division__code__in' },
        { name: 'Designations', prop: 'designationSet', type: Array, query: 'purchase__designation__in' },

        { name: 'Invoiced', prop: 'invoiced', type: String, query: 'invoiced' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' },
        { name: 'Needs Intake', prop: 'needsIntake', type: String, query: 'purchase__intake__isnull' },
        { name: 'Is Reconciled', prop: 'isReconciled', type: String, query: 'is_reconciled' },
        { name: 'Ref', prop: 'customerRef', type: String, query: 'customer_ref__iexact' }
      ]),
      divisions: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    fetchDivisions () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,url').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.name, url: d.url }))
        })
      }
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
